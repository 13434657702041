import React from 'react';
import Imgix from "react-imgix";


export default function DAO() {
  return (

    <section id="dao" className="section section-dao  bg-dark tc-light    ">
      <div className="container">
        <div className="row">

          <div className="col-mb-12 col-sm-12 col-md-6 ">
            <div className="cpn-head text-left ">
              <h1 className="title title-lg-s1 text-color-green line-height-1">
                DAO
              </h1>
            </div>

            <div className="cpn-head text-left">
              <p className="lead">
                The Martian Army aims to be a launchpad for new Artist & Creators while providing high yield to participants and owners through the DAO.
              </p>
              <h6 className="lead text-uppercase color-theme">Voting Mechanism</h6>
              <p className="lead">
                The Martian DAO will be using Quadratic Voting for all voting contracts, adding this way a more efficient and robust system.
              </p>

              <h6 className="lead text-uppercase color-theme">Voting Rights & Profit Sharing</h6>
              <p className="lead">
              In order to encourage users to participate in the Martian Army DAO token governance,
                the DAO FC will open the DAO pool staking function around during Q4 of 2022,
                with around 2222 million tokens (TBC)
              </p>
              <p className="lead">
              The DAO voting rights acquired by users are positively related to the number of staked <span className="text-color-green">$MARS</span>
              </p>
              <p className="lead">
              Check out our <a href={"https://app.gitbook.com/o/qHYSBulfVb36RV2CuNTz/s/0Lea8qbdhDEwPH55T9uL/tokenomics/dao#voting-rights"}> <span className="text-color-green">Whitepaper</span></a> for more info
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 display-mobile-none">
            <Imgix src="https://prod-martianarmy.imgix.net/v2/DAO.jpg"  sizes="30vw" width={800}/>
          </div>
        </div>






      </div>
    </section>

  );
}