import React from 'react';


export default function JUN2022() {
  return (

    <div className={"bg-theme rounded-lg"}>

      <div className={"row align-middle"}>
        <div className={"col-5 spacemap-border d-flex justify-content-center"}><h2 className={"ml-3 align-self-center bold-text"}>Jun 2022</h2></div>
        <div className={"col-7 d-flex justify-content-center  spacemap-border no-border-left"}><h5 className={"align-self-center"}>Stargate Launch<br/>& Spaceship 2</h5></div>
      </div>
      <div className={"row"}>
        <div className={"col-12 spacemap-border-2x"}>
          <p className={"ml-3 tc-dark bold-text"}>
            ›Stargate Web 3.0<br/>
            ›Exclusive access to Stargate web if you own at least 1 Martian<br/>
            ›Vote for the releases<br/>
            ›Spaceship 2 (Higher yields)<br/>
            ›Merchandise drop<br/>

          </p>
        </div>
      </div>

    </div>

  );
}