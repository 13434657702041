import React from 'react';


export default function FEB2023() {
  return (
    <div className={"bg-theme rounded-lg"}>
      <div className={"row"}>
        <div className={"col-5 spacemap-border d-flex justify-content-center"}><h2 className={"ml-3 align-self-center bold-text"}>Feb 2023</h2></div>
        <div className={"col-7 d-flex justify-content-center  spacemap-border no-border-left"}><h5 className={"align-self-center"}>EP1 Release<br/>& Spaceship 4</h5></div>
      </div>
      <div className={"row"}>
        <div className={"col-12 spacemap-border-2x"}>
          <p className={"ml-3 tc-dark bold-text"}>
            ›Special Limited Expansion Package (EP1) from Wave 1 [222 items]<br/>
            ›Spaceship 4 (yields TBD)<br/>
          </p>
        </div>
      </div>

    </div>
    

  );
}