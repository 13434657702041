import React from 'react';


export default function MAY2022() {
  return (
    <div className={"bg-theme rounded-lg"}>
  <div className={"row align-middle"}>
    <div className={"col-5 spacemap-border d-flex justify-content-center"}><h2 className={"ml-3 align-self-center bold-text"}>May 2022</h2></div>
    <div className={"col-7 d-flex justify-content-center  spacemap-border no-border-left"}><h5 className={"align-self-center"}>Rarity <br/>& Sec Markets </h5></div>
  </div>
  <div className={"row"}>
    <div className={"col-12 spacemap-border-2x"}>
      <p className={"ml-3 tc-dark bold-text"}>
        ›We will list on Alpha-Art, MagicEden and other secondary markets<br/>
        ›Deploy rarity in multiple Websites<br/>
        ›Discord channel to floor discussion, networking, meet-ups, exclusive holder events
      </p>
    </div>
  </div>

</div>
  );
}