import React from 'react';


export default function NOV2022() {
  return (
    <div className={"bg-theme rounded-lg"}>
      <div className={"row"}>
        <div className={"col-5 spacemap-border d-flex justify-content-center"}><h2 className={"ml-3 align-self-center bold-text"}>Nov 2022</h2></div>
        <div className={"col-7 d-flex justify-content-center  spacemap-border no-border-left"}><h5 className={"align-self-center"}>Limited Edition<br/>(Founder Level)</h5></div>
      </div>
      <div className={"row"}>
        <div className={"col-12 spacemap-border-2x"}>
          <p className={"ml-3 tc-dark bold-text"}>
            ›Special (Free) 22 Limited Edition Drop for owners and biggest supporters of Martian Army (the most NFTs holded in the first 6 months of project)<br/>
            ›Special Edition will come with a package future Governance Tokens included (1%/22 of the DAO)
          </p>
        </div>
      </div>
    </div>

  );
}