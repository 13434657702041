import React from 'react';
import Imgix from "react-imgix";


export default function ABOUT() {
  return (

    <section id="about" className="section bg-dark tc-light ov-h  bg-theme-martian-about-background">
      <div className="container">

        <div className="nk-block nk-block-features-2">
          <div className="row align-items-top gutter-vr-30px justify-content-center justify-content-md-between">
            <div className="col-mb-12 col-sm-12 col-md-12 ">
              <div className="cpn-head mt-0 tc-theme">
                <h2 className="title no-line-height text-uppercase text-color-green text-center">About The Martian Army?</h2>
              </div>
            </div>



            <div className="col-mb-12 col-sm-5 col-md-6 ">
              <div className="cpn-head nk-block-text tc-theme">
                <h6 className="lead text-uppercase color-theme">What is The Martian Army?</h6>
                <p className="lead text-justify">
                  The Martian Army is a collection of 2,222 randomly generated 1080x1080
                  pixel NFTs on the Solana Blockchain. Each Martian is unique and comes with different
                  visual traits and gaming attributes varying in rarity.
                </p>
                <h6 className="lead text-uppercase color-theme">Genesis Collection</h6>
                <p className="lead text-justify">
                  The Genesis collection are the first 333 Martian Minted and will be listed in Magic Eden and others
                </p>
                <h6 className="lead text-uppercase color-theme">What are Martian's traits?</h6>
                <p className="lead text-justify">
                  The Martian Army first wave possesses many random weapons, uniforms, armor, headwear, and backgrounds.
                  The first wave the army sent to scout are made of 333 unique soldiers.<br/>
                </p>
                <h6 className="lead text-uppercase color-theme">Why are they here?</h6>
                <p className="lead text-justify">
                  The Martian Army aims to be a launchpad for new Artist & Creators while
                  providing high yield to participants and owners through the DAO.
                </p>
              </div>


            </div>
            <div className="col-mb-12 col-sm-5 col-md-6 text-md-left">
              <br/>
              <div className="row">
                <div className="col-sm-4">
                  <Imgix width={220} src="https://prod-martianarmy.imgix.net/martian-banner.gif" sizes="10vw" imgixParams={{ q: "80", fm: "gif" }}/>
                </div>
                <div className="col-sm-4 display-mobile-none">
                  <Imgix width={220} src="https://prod-martianarmy.imgix.net/martian-banner-2.gif" sizes="10vw" imgixParams={{ q: "80", fm: "gif" }}/>
                </div>
                <div className="col-sm-4 display-mobile-none">
                  <Imgix width={220} src="https://prod-martianarmy.imgix.net/martian-banner-3.gif" sizes="10vw" imgixParams={{ q: "80", fm: "gif" }}/>
                </div>
              </div>

              <div className="cpn-head nk-block-text tc-theme">






                <h6 className="lead text-uppercase color-theme">Invasion</h6>
                <p className="lead text-justify">
                  The next Waves for the remaining full collection will be launched soon
                </p>
                <h6 className="lead text-uppercase color-theme">The Future of The Martion Army?</h6>
                <p className="lead text-justify">
                  Limited mints and special editions, a staking machine, breeding and evolution, mini games, VR games, metaverse and much more.
                  Check out the spacemap to learn more.
                </p>

              </div>
            </div>
          </div>
        </div>

      </div>
    </section>

  );
}