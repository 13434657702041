import { Button } from '@material-ui/core';
import React from 'react';
import Imgix from "react-imgix";


export default function UTILITY() {
  return (
    <section id="utility" className="section section-economics    bg-dark tc-light    bg-theme-martian-utility-background">
      <div className="container">

        <div className={"row utility-box-title"}>
          <div className={"d-flex"}>
            <div className="cpn-head text-left no-top-margin">
              <h1 className="title title-lg-s1 d-inline-block zero-padding zero-margin">
                <span className="color-theme-bold text-uppercase">Utility</span>
              </h1>

              <h4 className="  title-lg-s4  d-inline-block no-margin ">
                You can accumulate enough <span className={"text-color-green"}>$MARS</span> through<br/>
                Staking your Martians and unlock:
              </h4>

            </div>
          </div>
          <div className={"d-flex align-self-center float-right display-mobile-none"}>
            <Imgix className={"float-right"}  width={100} src="https://prod-martianarmy.imgix.net/v2/m-logo-round.png" sizes={"10vw"}/>
          </div>
        </div>



        <div className="row align-items-top  justify-content-center">


          <div className=" col-lg-3  text-center text-md-center cpn-head ">
            <Imgix width={320} className="utilities-img" src="https://prod-martianarmy.imgix.net/v2/utilities/spaceship.png" />
            <h4 className="title title-lg-s4">
              <span className="color-theme-bold">SPACESHIP</span>
            </h4>
            <p className="utility-text lead text-left ">
              Your rewards for staking start with our Genesis Spaceship.<br/>
              Stake your Martian NFT in the Spaceship to earn 162<span className={"text-color-green"}>$MARS</span> per day.<br/>
              You will also be able to buy your own Spaceship in the future and run your own staking machine.<br/>
              Having a private Spaceship means you can earn higher yield for your own Martians, and you'll also be able to rent bunk space to other Martians and split your earnings.
            </p>
            <div className="col-10 offset-1 ">
              <Button id="spaceship" variant="contained"
                      className=" bold-text btn-round btn-lg spaceship-active"
                      href="https://main.d27xb4rgthcnfo.amplifyapp.com/" rel="noreferrer"
              >Genesis Spaceship
              </Button>
            </div>
          </div>


          <div className="col-lg-3  text-center text-md-center cpn-head">
            <Imgix width={320} className="utilities-img" src="https://prod-martianarmy.imgix.net/v2/utilities/incubator.png" />
            <h4 className="title title-lg-s4">
              <span className="color-theme-bold">Incubator 3.0</span>
            </h4>
            <p className="utility-text lead text-left">
              When two Martians love each other very much they climb into the MarsTec Incubator 3.0 and with the magic of MarsTec technology they can make a brand new baby Martian.
              <br/>
              Combine two of your Martian NFTs in the incubator and mix in a little <span className={"text-color-green"}>$MARS</span> token and you too can make your own love child.

              Imbue your baby Martian with MarTec Evolution 3.0 to make them into a terrifying soldier for the Martian Army.
            </p>
            <div className="col-10 offset-1">
              <Button id="incubator" variant="contained"
                      className=" bold-text btn-round btn-lg spaceship"
              >Coming Soon
              </Button>
            </div>
          </div>

          <div className=" col-lg-3  text-center text-md-center cpn-head ">
            {/*<img className="utilities-img" src={'/images/utilities/evolution.png'} alt="Incubator Evolution "></img>*/}
            <Imgix width={320} className="utilities-img" src="https://prod-martianarmy.imgix.net/v2/utilities/evolution.png" sizes="30vw"/>
            <h4 className="title title-lg-s4">
              <span className="color-theme-bold">Evolution 3.0</span>
            </h4>
            <p className="utility-text lead  text-left ">

              With the help of MarsTec's Evolution 3.0 technology, you too can fast-track the burdensome job of raising a child into a happy, healthy and horrifying Martian soldier.
              <br/><br/>
              Evolution 3.0 allows you to grow your baby Martian NFT into a fully-grown adult Martian Army soldier.
              <br/>
              Endow your new Martian with special and limited traits by participating in the Martian ecosystem.

            </p>
            <div className="col-10 offset-1">
              <Button id="incubator-evolution" variant="contained"
                      className=" bold-text btn-round btn-lg spaceship"
              >Coming Soon
              </Button>
            </div>
          </div>


          <div className=" col-lg-3  text-center text-md-center cpn-head">
            {/*<img className="utilities-img" src={'/images/utilities/metaverse.png'} alt="Metaverse "></img>*/}
            <Imgix width={320} className="utilities-img" src="https://prod-martianarmy.imgix.net/v2/utilities/metaverse.png" sizes="30vw"/>
            <h4 className="title title-lg-s4">
              <span className="color-theme-bold">Metaverse</span>
            </h4>
            <p className="utility-text lead  text-left">

              The Martian home world will be simulated in the metaverse.
              <br/><br/>
              In the Martian Metaverse NFT owners will have access to a plot of land where they can build their outpost, terraform the planet, park their spaceships, display their artwork.
              <br/><br/>
              Participate in games and special events with one of our collaboration partners.

            </p>
            <div className="col-10 offset-1 align-bottom">
              <Button id="metaverse" variant="contained"
                      className=" bold-text btn-round btn-lg spaceship"
              >Coming Soon
              </Button>
            </div>
          </div>

        </div>





        <div className="row align-items-top  justify-content-center">
          <div className=" col-lg-3  text-center text-md-center cpn-head">
            {/*<img className="utilities-img" src={'/images/v2/utilities/minigame.png'} alt=" "></img>*/}
            <Imgix width={320} className="utilities-img" src="https://prod-martianarmy.imgix.net/v2/utilities/minigame.png" sizes="30vw"/>
            <h4 className="title title-lg-s4">
              <span className="color-theme-bold">Mini Games 3.0</span>
            </h4>
            <p className="lead text-md-left text-left">

              The MA Ecosystem will include a number of mini games chosen and developed by our community.
              <br/>
              Our first one is already in beta and you can play it now. Each year, the DAO will choose up to ten new developers and their games to add to the ecosystem.
              <br/>
              All mini games will be an experiment in web3 development, earn2play mechanics, and will contribute to the value of <span className={"text-color-green"}>$MARS</span> and the Martian economy.

            </p>
            <div className="col-10 offset-1 ">
              <Button id="spaceship" variant="contained"
                      className="bold-text btn-round btn-lg spaceship-active"
                      href="/mini-games" rel="noreferrer"
              >Genesis Game
              </Button>
            </div>
          </div>
          <div className=" col-lg-3  text-center text-md-center cpn-head ">
            <Imgix width={320} className="utilities-img" src="https://prod-martianarmy.imgix.net/v2/utilities/gaming.png" sizes="30vw"/>
            <h4 className="title title-lg-s4">
              <span className="color-theme-bold">Battle for Earth 3.0</span>
            </h4>
            <p className="lead text-md-left text-justify ">

              Battle for Earth 3.0 will be a web3 card-style game where you will battle for control over the World.
              <br/><br/>
              Cards will be NFTs and your Martian Army NFTs will have a role to play in your deck.
              <br/><br/>
              The winner mini games will be incorporated into the game and an immersive VR experience will be added later on.
              <br/>
            </p>
            <div className="col-10 offset-1 ">
              <Button id="spaceship" variant="contained"
                      className=" bold-text btn-round btn-lg spaceship"
                      href="" rel="noreferrer"
              >Coming Soon
              </Button>
            </div>
          </div>
          <div className=" col-lg-3  text-center text-md-center cpn-head">
            {/*<img className="utilities-img" src={'/images/v2/utilities/arvrgame.png'} alt="Metaverse "></img>*/}
            <Imgix width={320} className="utilities-img" src="https://prod-martianarmy.imgix.net/v2/utilities/arvrgame.png" sizes="30vw"/>
            <h4 className="title title-lg-s4">
              <span className="color-theme-bold">AR/VR Game</span>
            </h4>
            <p className="lead text-md-left text-left">

              It's not enough to just visit the Martian homeworld.  You will want to experience it, occupy it, embody it.  Smell that fresh rusty Martian air.
              <br/><br/>
              Martian Army mini games, Battle for Earth card game, and Martian Metaverse will be ported over to VR and AR so they can be experienced in first person at ground level.
              <br/>&nbsp;
            </p>
            <div className="col-10 offset-1 ">
              <Button id="metaverse" variant="contained"
                      className=" bold-text btn-round btn-lg spaceship"
              >Coming Soon
              </Button>
            </div>
          </div>
        </div>

      </div>
    </section>


  );
}