import 'roboto-regular';


import FAQ from './components/FAQ';
import FOOTERCONTENT from "./components/FOOTERCONTENT";
import TEAMS from "./components/TEAMS";
import UTILITY from "./components/UTILITY";
import ECONOMICS from "./components/ECONOMICS";
import ABOUT from "./components/ABOUT";
import MARKEE from "./components/MARKEE";
import TOPMENU from "./components/TOP-MENU";
import HEADERBANNER from "./components/HEADER";
import ROADMAPV2 from "./components/ROADMAPV2";
import DAO from "./components/DAO";
import CONSULTING from "./components/CONSULTING";

export interface HomeProps {

}

const Home = (props: HomeProps) => {


  return (
    <div>
      <header className="nk-header page-header is-transparent is-sticky is-shrink bg-dark is-dark has-fixed" id="header">

        <TOPMENU/>

        <HEADERBANNER/>
      </header>


      <main className="nk-pages">

         <MARKEE/>

        <ABOUT/>



        <UTILITY/>

        <CONSULTING/>

        <ECONOMICS/>

        <DAO/>

        <ROADMAPV2/>



        <TEAMS/>

       <FAQ />

      </main>

      <footer className="nk-footer bg-theme tc-dark bg-theme-martian-footer-background">
        <FOOTERCONTENT />
      </footer>
    </div>

  );
};

export default Home;
