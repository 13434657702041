import React, {useState} from 'react';
import {HashLink as Link} from "react-router-hash-link";
import { useLocation } from 'react-router-dom'

export default function TOPMENU() {
  const location = useLocation();
  let  mint = false;
  if(location.pathname === "/mint"){
    mint =true;
  }
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  return (

    <div className="header-main">
      <div className="header-container container">
        <div className="header-wrap">
          <div className="header-logo logo">
            <a href="/#" className="logo-link">
              <img className="logo-light" src="/images/logo.png" alt="logo"></img>
            </a>
          </div>
          <div className="header-nav-toggle">

            <a href="/#" id="mobile-menu-toggle" className={"navbar-toggle mint-"+mint} data-menu-toggle="example-menu-04"
               onClick={() => setMobileMenuActive(!mobileMenuActive)}>
              <div className="toggle-line">
                <span></span>
              </div>
            </a>

            <div className={"reload-div mint-"+mint}>
              <a href="/mint" rel="noreferrer"><em className="fa fa-refresh fa-1-5x"></em></a>
            </div>



          </div>
          <div className={"header-navbar header-navbar-s1 menu-border-bottom " + (mobileMenuActive ? "mobile-menu-active" : "")}>
            <nav className="header-menu" id="example-menu-04 menu-border-bottom">
              <ul className="menu menu-s2 align-items-end social">
                <li className="menu-item"><a href="https://magiceden.io/marketplace/marsarmy">
                  <img src={"/images/partner/magic-eden.png"} width={"32px"} alt={"Magic Eden"}/>
                </a></li>
                <li className="menu-item"><Link to="/#utility" onClick={() => setMobileMenuActive(false)}>Utility</Link></li>
                <li className="menu-item"><Link to="/#economics" onClick={() => setMobileMenuActive(false)}>Token</Link></li>
                <li className="menu-item"><Link to="/#dao" onClick={() => setMobileMenuActive(false)}>DAO</Link></li>
                <li className="menu-item"><Link to="/#spacemap"onClick={() => setMobileMenuActive(false)}>Spacemap</Link></li>
                <li className="menu-item"><Link to="/mini-games"onClick={() => setMobileMenuActive(false)}>Game</Link></li>
                <li className="menu-item"><Link to="/whitepaper"onClick={() => setMobileMenuActive(false)}>Whitepaper</Link></li>
                <li className="menu-item"><Link to="/#team" onClick={() => setMobileMenuActive(false)}>Team</Link></li>
                <li className="menu-item"><Link to="/#FAQ-SECTION" onClick={() => setMobileMenuActive(false)}>FAQ</Link></li>

                <li className="menu-item"><a  href="https://ship.martianarmy.space/" rel="noreferrer" >SpaceShip</a></li>

                <li className="menu-item social"><a target="_blank" href="https://twitter.com/_martianarmy_"
                                                    rel="noreferrer"><em className="fab fa-twitter fa-1-5x"></em></a></li>
                <li className="menu-item social"><a target="_blank" href=" https://discord.gg/martianarmy"
                                                    rel="noreferrer"><em className="fab fa-discord fa-1-5x"></em></a></li>
                <li className="menu-item social"><a target="_blank"
                                                    href="https://www.instagram.com/martianarmy.space/"
                                                    rel="noreferrer"><em className="fab fa-instagram fa-1-5x"></em></a></li>
                <li className="menu-item social"><a target="_blank"
                                                    href="https://medium.com/@martianarmy"
                                                    rel="noreferrer"><em className="fab fa-medium fa-1-5x"></em></a></li>
                <li className="menu-item social"><a target="_blank"
                                                    href="https://www.youtube.com/channel/UCqTtT-nIpb7KwG9CvqF23mQ/featured"
                                                    rel="noreferrer"><em className="fab fa-youtube fa-1-5x"></em></a></li>
              </ul>
            </nav>

          </div>
        </div>
      </div>
    </div>

  );
}