import React from 'react';
import Imgix from "react-imgix";


export default function CONSULTING() {
  return (

    <section id="dao" className="section section-dao  bg-dark tc-light    ">
      <div className="container">
        <div className="row">

          <div className="col-mb-12 col-sm-12 col-md-6 ">
            <div className="cpn-head text-left ">
              <h1 className="title title-lg-s1 text-color-green line-height-1">
                Consulting
              </h1>
            </div>

            <div className="cpn-head text-left">
              <p className="lead">
                The Martian Army also provides consulting for new NFT projects<br/>
                Contact us in our discord our twitter for more details<br/>
                Check our <a href="https://github.com/TheMartianArmy/" rel="noreferrer" target={"blank"}><em className="fa fa-github fa-1-5x"></em>&nbsp;Github Account</a>  for our open source projects
              </p>
              <h6 className="lead text-uppercase color-theme">Staking Machine</h6>
              <p className="lead">
                The fastest Staking Machine in the market (3s loading)<br/>
                Hosting<br/>
                Regular updates<br/>
                Support<br/>
              </p>
              <h6 className="lead text-uppercase color-theme">Smart Contract</h6>
              <p className="lead">
                Open Source Smart Contract<br/>
                Smart Contract Customization<br/>
                Smart Contract Deployment<br/>
                Smart Contract Maintenance<br/>
                Staking Account creation<br/>
                Token creation<br/>
                Token Maintenance<br/>
              </p>


            </div>
          </div>
          <div className="col-sm-12 col-md-6 display-mobile-none">
            <div className="row">
            <div className="cpn-head text-left col-12">
              <Imgix src="https://prod-martianarmy.imgix.net/v2/Consulting/Staking-machine.jpg"  className={"consulting-img"}  width={662} height={450} sizes="300vw" />
            </div>
            <div className="cpn-head text-left col-12">
              <h6 className="lead text-uppercase color-theme">Github</h6>
              <p className="lead">
                Checkout out open source repos:<br/>
                Frontend&nbsp;<a href="https://github.com/TheMartianArmy/Spaceship-V1" rel="noreferrer" target={"blank"}><em className="fa fa-github fa-1-5x"></em>&nbsp;github.com/TheMartianArmy/Spaceship-V1</a><br/>
                Contract&nbsp;<a href="https://github.com/TheMartianArmy/Spaceship-Contract-V1" rel="noreferrer" target={"blank"}><em className="fa fa-github fa-1-5x"></em>&nbsp;github.com/TheMartianArmy/Spaceship-Contract-V1</a><br/>
              </p>
            </div>
            </div>
          </div>
        </div>






      </div>
    </section>

  );
}