import React from 'react';


export default function APR2022() {
  return (
    <div className={"bg-theme rounded-lg"}>
    <div className={"row align-middle"}>
      <div className={"col-5  spacemap-border d-flex justify-content-center"}><h2 className={"ml-3 align-self-center bold-text"}>Apr 2022</h2></div>
      <div className={"col-7 d-flex justify-content-center  spacemap-border no-border-left"}><h5 className={"align-self-center"}>Martian Army<br/> & SpaceShip</h5></div>
    </div>
  <div className={"row"}>
    <div className={"col-12 spacemap-border-2x"}>
      <p className={"ml-3 tc-dark bold-text"}>
        ›Genesis Release of 333 Martians<br/>
        ›Staking Spaceship Genesis for passive earning<br/>
        ›$MARS Token Launch<br/>
        ›Launch first Mini-Game
      </p>
    </div>
  </div>
</div>
  );
}