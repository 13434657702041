
import React from 'react';
import Imgix from "react-imgix";


export default function TEAMS() {
  return (

    <section id="team" className="section  section-team   bg-dark tc-light    bg-theme-martian-team-background ">
      <div className="container">
        <div className="section-head section-head-sm wide-auto-sm cpn-head ">
          <h1 className="title title-lg-s1 color-theme text-center">Our Team</h1>
        </div>
        <div className=" ">
          <div className="row justify-content-center">

            <div className="col-lg-4 col-mb-6 col-6">
              <div className={"row"}>
                <div className="team team-s3 team-s3-alt  col-md-6 no-padding">
                  <div className="team-photo round-full team-photo-bg">
                    <Imgix width={220} src="https://prod-martianarmy.imgix.net/martian_army/paulo.JPG" sizes="50vw" className="round-full"/>
                  </div>
                  <div className="menu-item social d-flex justify-content-center">
                    <a target="_blank" href="https://twitter.com/PauloNA" rel="noreferrer">
                    <em className="fab fa-twitter fa-1-5x"></em></a>
                  </div>
                </div>
                <div className={" col-md-6 text-left zero-padding"}>
                  <h5 className="team-name title title-sm">Oluap Adiemla</h5>
                  <span className="team-position">Founder</span>
                  <span className="team-position-details">15+ years managing software development startups</span>
                  <span className="team-position-details">## Currently ##</span>
                  <span className="team-position-details">CTO @ Fintech Startup</span>
                  <span className="team-position-details">AWS Scout</span>
                  <span className="team-position-details">Startup Adviser</span>
                </div>
              </div>
            </div>



            <div className="col-lg-4 col-mb-6 col-6">
              <div className={"row"}>
                <div className="team team-s3 team-s3-alt  col-md-6 no-padding">
                  <div className="team-photo round-full team-photo-bg">
                    <Imgix width={220} src="https://prod-martianarmy.imgix.net/martian_army/sandy-martian.JPG" sizes="50vw" className="round-full"/>
                  </div>
                  <div className="menu-item social d-flex justify-content-center">
                    <a target="_blank" href="https://twitter.com/sandychuchat" rel="noreferrer">
                    <em className="fab fa-twitter fa-1-5x"></em></a>
                    <a target="_blank" href="https://www.instagram.com/sandychuchat/" rel="noreferrer">
                      <em className="fab fa-instagram fa-1-5x"></em></a>
                  </div>
                </div>
                <div className={" col-md-6 text-left zero-padding"}>
                  <h5 className="team-name title title-sm">Ydnas</h5>
                  <span className="team-position">Chief Artist</span>
                  <span className="team-position-details">Acclaimed NFT Artist</span>
                  <span className="team-position-details">Early NFT adopter</span>
                  <span className="team-position-details">Sales across multiple blockchain, ETH, Tezos, Polygon, Solana</span>
                </div>
              </div>
            </div>


            <div className="col-lg-4 col-mb-6 col-6">
              <div className={"row"}>
                <div className="team team-s3 team-s3-alt  col-md-6 no-padding">
                  <div className="team-photo round-full team-photo-bg">
                    <Imgix width={220} src="https://prod-martianarmy.imgix.net/martian_army/James.JPG" sizes="50vw" className="round-full"/>
                  </div>

                </div>
                <div className={" col-md-6 text-left zero-padding"}>
                  <h5 className="team-name title title-sm">Lord Semaj</h5>
                  <span className="team-position">Chief Marketing Officer</span>

                  <span className="team-position-details">15+ years of startup experience</span>
                  <span className="team-position-details">The one with the Midas touch</span>
                  <span className="team-position-details">Built over $2bill USD in value over 4 startups</span>

                </div>
              </div>
            </div>
            <div className="col-lg-4 col-mb-6 col-6">
              <div className={"row"}>
                <div className="team team-s3 team-s3-alt  col-md-6 no-padding">
                  <div className="team-photo round-full team-photo-bg">
                    <Imgix width={220} src="https://prod-martianarmy.imgix.net/martian_army/8.png" sizes="50vw" className="round-full"/>
                  </div>
                  <div className="menu-item social d-flex justify-content-center"><a target="_blank" href="https://twitter.com/martian_matherz" rel="noreferrer">
                    <em className="fab fa-twitter fa-1-5x"></em></a>
                  </div>
                </div>
                <div className={" col-md-6 text-left zero-padding"}>
                  <h5 className="team-name title title-sm">Nedyah</h5>
                  <span className="team-position">Chief Web3.0</span>
                  <span className="team-position-details">Early Adopter of Blockchain tech</span>
                  <span className="team-position-details">Chief of Drones & Risk Advisor for a commodities producer</span>
                  <span className="team-position-details">Founder of a BioHacking Centre</span>

                </div>
              </div>
            </div>

            <div className="col-lg-4 col-mb-6 col-6">
              <div className={"row"}>
                <div className="team team-s3 team-s3-alt  col-md-6 no-padding">
                  <div className="team-photo round-full team-photo-bg">
                    <Imgix width={220} src="https://prod-martianarmy.imgix.net/martian_army/4.png" sizes="50vw" className="round-full"/>
                  </div>
                  <div className="menu-item social d-flex justify-content-center"><a target="_blank" href="https://twitter.com/UncleJammie" rel="noreferrer">
                    <em className="fab fa-twitter fa-1-5x"></em></a>
                  </div>
                </div>
                <div className={" col-md-6 text-left zero-padding"}>
                  <h5 className="team-name title title-sm">Mas Aksala</h5>
                  <span className="team-position">Chief Story Officer</span>
                  <span className="team-position-details">Head writer and Master Spy</span>
                  <span className="team-position-details">15+ years in education industry</span>

                </div>
              </div>
            </div>
            <div className="col-lg-4 col-mb-6 col-6">
              <div className={"row"}>
                <div className="team team-s3 team-s3-alt  col-md-6 no-padding">
                  <div className="team-photo round-full team-photo-bg">
                    <Imgix width={220} src="https://prod-martianarmy.imgix.net/martian_army/5.png" sizes="50vw" className="round-full"/>
                  </div>
                  <div className="menu-item social d-flex justify-content-center"><a target="_blank" href="https://twitter.com/angelomiguelvaz" rel="noreferrer">
                    <em className="fab fa-twitter fa-1-5x"></em></a>
                  </div>
                </div>
                <div className={" col-md-6 text-left zero-padding"}>
                  <h5 className="team-name title title-sm">Zav Olegna</h5>
                  <span className="team-position">Chief Brand Officer</span>
                  <span className="team-position-details">16+ year of Designing experience</span>
                  <span className="team-position-details">UX/UI and Brand Master on Top 10 European Advertising Group</span>
                  <span className="team-position-details"></span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-mb-6 col-6">
              <div className={"row"}>
                <div className="team team-s3 team-s3-alt  col-md-6 no-padding">
                  <div className="team-photo round-full team-photo-bg">
                    <Imgix width={220} src="https://prod-martianarmy.imgix.net/martian_army/6.png" sizes="50vw" className="round-full"/>
                  </div>
                </div>
                <div className={" col-md-6 text-left zero-padding"}>
                  <h5 className="team-name title title-sm">Alitta</h5>
                  <span className="team-position">Solana Architect</span>
                  <span className="team-position-details">10+ Year of Software Development</span>
                  <span className="team-position-details">## Currently ##</span>
                  <span className="team-position-details">Architect and Head Of DevOps for a Fintech</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-mb-6 col-6">
              <div className={"row"}>
                <div className="team team-s3 team-s3-alt  col-md-6 no-padding">
                  <div className="team-photo round-full team-photo-bg">
                    <Imgix width={220} src="https://prod-martianarmy.imgix.net/martian_army/7.png" sizes="50vw" className="round-full"/>
                  </div>
                </div>
                <div className={" col-md-6 text-left zero-padding"}>
                  <h5 className="team-name title title-sm">Ivax</h5>
                  <span className="team-position">Gaming Architect</span>
                  <span className="team-position-details">10+ Year of Software Development</span>
                  <span className="team-position-details">Native App Specialist</span>
                  <span className="team-position-details"></span>
                  <span className="team-position-details"></span>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-mb-6 col-6">
              <div className={"row"}>
                <div className="team team-s3 team-s3-alt  col-md-6 no-padding">
                  <div className="team-photo round-full team-photo-bg">

                    <Imgix width={220} src="https://prod-martianarmy.imgix.net/martian_army/1.png" sizes="50vw" className="round-full"/>
                  </div>
                </div>
                <div className={" col-md-6 text-left zero-padding"}>
                  <h5 className="team-name title title-sm">This could be you</h5>
                  <span className="team-position">Web Designer</span>
                  <span className="team-position-details">Contact us in discord for more details</span>

                </div>
              </div>
            </div>

            <div className="col-lg-4 col-mb-6 col-6">
              <div className={"row"}>
                <div className="team team-s3 team-s3-alt  col-md-6 no-padding">
                  <div className="team-photo round-full team-photo-bg">
                    <Imgix width={220} src="https://prod-martianarmy.imgix.net/martian_army/Jenrifer.JPG" sizes="50vw" className="round-full"/>
                  </div>
                </div>
                <div className={" col-md-6 text-left zero-padding"}>
                  <h5 className="team-name title title-sm">Queen Nnej</h5>
                  <span className="team-position">Advisor & Angel Investor</span>
                  <span className="team-position-details">Ex Google</span>
                  <span className="team-position-details">## Currently ##</span>
                  <span className="team-position-details">CDO/CTO, Investor & Mentor</span>
                  <span className="team-position-details">Billions of usd created in Business Value</span>
                  <span className="team-position-details"></span>
                </div>
              </div>
            </div>


            <div className="col-lg-4 col-mb-6 col-6">
              <div className={"row"}>
                <div className="team team-s3 team-s3-alt  col-md-6 no-padding">
                  <div className="team-photo round-full team-photo-bg">
                    <Imgix width={220} src="https://prod-martianarmy.imgix.net/martian_army/16.png" sizes="50vw" className="round-full"/>
                  </div>
                </div>
                <div className={" col-md-6 text-left zero-padding"}>
                  <h5 className="team-name title title-sm">Lexa Retniw</h5>
                  <span className="team-position">Advisor & Angel Investor</span>
                  <span className="team-position-details">30+ year of CTO position</span>
                  <span className="team-position-details">Pioneer in crypto usage in Corporations</span>
                  <span className="team-position-details">Billions of usd created in e-commerce just in the last 10 years</span>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-mb-6 col-6">
              <div className={"row"}>
                <div className="team team-s3 team-s3-alt  col-md-6 no-padding">
                  <div className="team-photo round-full team-photo-bg">
                    <Imgix width={220} src="https://prod-martianarmy.imgix.net/martian_army/15.png" sizes="50vw" className="round-full"/>
                  </div>
                </div>
                <div className={" col-md-6 text-left zero-padding"}>
                  <h5 className="team-name title title-sm">Oicirbaf VC</h5>
                  <span className="team-position">Advisor & Angel Investor</span>
                  <span className="team-position-details">20+ years in Real Estate Investment Banking, Private Equity and Venture Capital</span>
                  <span className="team-position-details">Pioneer in the tokenization of Real Estate Assets</span>
                  <span className="team-position-details">Early employee Lava Trading</span>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </section>


  );
}