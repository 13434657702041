import React from 'react';


export default function JAN2023() {
  return (
    <div className={"bg-theme rounded-lg"}>
  <div className={"row"}>
    <div className={"col-5 spacemap-border d-flex justify-content-center"}><h2 className={"ml-3 align-self-center bold-text"}>Jan 2023</h2></div>
    <div className={"col-7 d-flex justify-content-center  spacemap-border no-border-left"}><h5 className={"align-self-center"}>DAO Voting<br/>& Spaceship 3</h5></div>
  </div>
  <div className={"row"}>
    <div className={"col-12 spacemap-border-2x"}>
      <p className={"ml-3 tc-dark bold-text"}>
        DAO vote for 2 new Artist for 2023 collections<br/>
        DAO vote for 8 new Artist for 2023 Incubator & Evolution machines<br/>
        ›Spaceship 3 (Higher yields & monthly lockdown)<br/>
      </p>
    </div>
  </div>

</div>
  );
}