import { useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';
import * as anchor from '@project-serum/anchor';
import Home from './Home';
import { DEFAULT_TIMEOUT } from './connection';
import ZPSale from './ZPSale';
import ListMiniGames from "./ListMiniGames";
import WhitePaper from "./whitepaper";
import Spaceship from "./spaceship";
import Genesis from "./genesis-ani";
import NFTGame from "./NFTGame";
// import SaleAlpha from "./SaleAlpha";

import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from '@solana/wallet-adapter-wallets';

import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletDialogProvider } from '@solana/wallet-adapter-material-ui';

import { ThemeProvider, createTheme } from '@material-ui/core';

const theme = createTheme({
  palette: {
    type: 'dark',
  },
});

const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    const candyMachineId = new anchor.web3.PublicKey(
      process.env.REACT_APP_CANDY_MACHINE_ID!,
    );

    return candyMachineId;
  } catch (e) {
    console.log('Failed to construct CandyMachineId', e);
    return undefined;
  }
};

// const getCandyMachineIdAlpha = (): anchor.web3.PublicKey | undefined => {
//   try {
//     return new anchor.web3.PublicKey(
//       process.env.REACT_APP_CANDY_MACHINE_ID_ALPHA!
//     );
//   } catch (e) {
//     console.log('Failed to construct Alpha Collection CandyMachineId', e);
//     return undefined;
//   }
// };

const candyMachineId = getCandyMachineId();
const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(
  rpcHost ? rpcHost : anchor.web3.clusterApiUrl('devnet'),
);

// const candyMachineIdAlpha = getCandyMachineIdAlpha();

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSlopeWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [],
  );

  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletDialogProvider>
            <Routes>
              <Route path="/" element={<Home
            />} />
              <Route path="genesis-ani" element={<Genesis

              />} />
              <Route path="zpsale" element={<ZPSale
              candyMachineId={candyMachineId}
              connection={connection}
              txTimeout={DEFAULT_TIMEOUT}
              rpcHost={rpcHost}
              network={network}
            />} />
            <Route path="mint" element={<ZPSale
              candyMachineId={candyMachineId}
              connection={connection}
              txTimeout={DEFAULT_TIMEOUT}
              rpcHost={rpcHost}
              network={network}
            />} />
              {/*<Route path="salealpha" element={<SaleAlpha*/}
              {/*  candyMachineId={candyMachineIdAlpha}*/}
              {/*  connection={connection}*/}
              {/*  txTimeout={DEFAULT_TIMEOUT}*/}
              {/*  rpcHost={rpcHost}*/}
              {/*  network={network}*/}
              {/*/>} />*/}

              <Route path="games" element={<NFTGame
              />} />

              <Route path="mini-games" element={<ListMiniGames
                connection={connection}
                rpcHost={rpcHost}
              />} />

              <Route path="whitepaper" element={<WhitePaper

              />} />

              <Route path="spaceship" element={<Spaceship

              />} />
              

          </Routes>

          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );
};

export default App;
