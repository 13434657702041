import React from 'react';


export default function Q32023() {
  return (
    <div className={"bg-theme rounded-lg"}>
      <div className={"row"}>
        <div className={"col-5 spacemap-border d-flex justify-content-center"}><h2 className={"ml-3 align-self-center bold-text"}>Q3 2023</h2></div>
        <div className={"col-7 d-flex justify-content-center  spacemap-border no-border-left"}><h5 className={"align-self-center"}>Metaverse<br/>& EP2 Release</h5></div>
      </div>
      <div className={"row"}>
        <div className={"col-12 spacemap-border-2x"}>
          <p className={"ml-3 tc-dark bold-text"}>
            ›(Beta Launch) Every NFT owner will have there plot of land/s on Martian the Metaverse<br/>
            ›Special Limited Expansion Package (EP1) from Wave 2 [222 items]<br/>
          </p>
        </div>
      </div>

    </div>
    

  );
}