

export interface GenesisProps {

}

const Genesis = (props: GenesisProps) => {

  return (
    <div>
      <header>
      im here
      </header>
    </div>
  );
};

export default Genesis;
