import * as anchor from "@project-serum/anchor";
import TOPMENU from "./components/TOP-MENU";

// import { clusterApiUrl, Connection, PublicKey } from "@solana/web3.js";
// import bs58 from "bs58";
// import { useWallet } from "@solana/wallet-adapter-react";

export interface ListMiniGamesProps {
  connection: anchor.web3.Connection;
  rpcHost: string;
}

const ListMiniGames = (props: ListMiniGamesProps) => {
  // const [mobileMenuActive, setMobileMenuActive] = useState(false);

  // const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
  // const connection = new Connection(
  //   rpcHost ? rpcHost : clusterApiUrl("devnet")
  // );
  // const wallet = useWallet();

  // const anchorWallet = useMemo(() => {
  //   if (
  //     !wallet ||
  //     !wallet.publicKey ||
  //     !wallet.signAllTransactions ||
  //     !wallet.signTransaction
  //   ) {
  //     return;
  //   }

  //   return {
  //     publicKey: wallet.publicKey,
  //     signAllTransactions: wallet.signAllTransactions,
  //     signTransaction: wallet.signTransaction,
  //   } as anchor.Wallet;
  // }, [wallet]);

  // const ownerPublickey = wallet.publicKey;
  // const nftsmetadata = await Metadata.findDataByOwner(
  //   connection,
  //   ownerPublickey
  // );
  // console.log(nftsmetadata);

  return (
    <div>
      <header
        className="nk-header page-header is-transparent is-sticky is-shrink is-dark has-fixed"
        id="header"
      >
        <TOPMENU/>

        <div className="header-banner bg-theme-martian-background has-ovm has-mask"id="home">
          <div className="nk-banner">
            <div className="banner-mask-fix banner-fs banner-single tc-light">
              <div className="banner-wrap ov-v">
                <div className="container">
                  <div className="row align-items-center justify-content-center justify-content-lg-between">
                    <div className="col-lg-12" >
                      <iframe title={"genesis game"} className={"game-frame"} src="https://gotm.io/pudpud/marsattack?forceFullscreen=true%22%3E"/>
                    </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default ListMiniGames;
