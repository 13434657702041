import React from 'react';


export default function AUG2022() {
  return (
    <div className={"bg-theme rounded-lg"}>
      <div className={"row align-middle"}>
        <div className={"col-5 spacemap-border d-flex justify-content-center"}><h2 className={"ml-3 align-self-center bold-text"}>Aug 2022</h2></div>
        <div className={"col-7 d-flex justify-content-center  spacemap-border no-border-left"}><h5 className={"align-self-center"}>Open Call <br/>Mini Games <br/> Incubator3.0</h5></div>
      </div>
      <div className={"row"}>
        <div className={"col-12 spacemap-border-2x"}>
          <p className={"ml-3 tc-dark bold-text"}>
            ›Open Call for 10 Developers<br/>
            ›Community vote on 5 for Martian Army to invest<br/>
            ›Incubator 3,0 Breath your unique 2Gen baby Martians (New Artist every Quarter)

          </p>
        </div>
      </div>

    </div>
    

  );
}