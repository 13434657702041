import React from 'react';
import Imgix from "react-imgix";


export default function ECONOMICS() {
  return (

    <section id="economics" className="section section-economics  bg-dark tc-light    ">
      <div className="container">
        <div className="row">
          <div className="col-mb-12 col-sm-12 col-md-6 display-mobile-none">
            <Imgix width={400} src="https://prod-martianarmy.imgix.net/v2/Token.png" className={"token-image"} sizes={"30vw"}/>
          </div>
          <div className="col-mb-12 col-sm-12 col-md-6 ">
            <div className="cpn-head text-left ">
              <h1 className="title title-lg-s1 text-color-green line-height-1">
                TOKENOMICS<br/>
                <span  className="text-color-white">$MARS</span></h1>
            </div>

            <div className="cpn-head text-left">
              <p className="lead">
                Staking Martians in the Spaceship earns you MARS tokens. <span className="text-color-green">$MARS</span> is the governance token for the Martian DAO.
                <br/>
                The price of <span className="text-color-green">$MARS</span> in $USDC will be based on market dynamics of supply and demand, but demand will be powered by a mini economy inside the Martian Army ecosystem.
                <br/>
                The tokenomics of <span className="text-color-green">$MARS</span> has been designed to provide value back to investors and encourage participation in the ecosystem.
              </p>

            </div>
            <div className={"row"} id={"table-investment"}>
              <div className="col-4 col-mb-4 col-sm-4 col-md-4 ">
                <div className="cpn-head text-center ">
                  <h1 className="title title-lg-s1 text-color-green">
                    40%
                  </h1>
                  <h4 className=" text-uppercase  title-lg-s4 ">
                    staking rewards
                  </h4>
                </div>
              </div>
              <div className="col-4 col-mb-4 col-sm-4 col-md-4 ">
                <div className="cpn-head text-center ">
                  <h1 className="title title-lg-s1 text-color-green">
                    10%
                  </h1>
                  <h4 className=" text-uppercase  title-lg-s4 ">
                    LP rewards
                  </h4>
                </div>
              </div>
              <div className="col-4 col-mb-4 col-sm-4 col-md-4 ">
                <div className="cpn-head text-center ">
                  <h1 className="title title-lg-s1 text-color-green">
                    10%
                  </h1>
                  <h4 className=" text-uppercase  title-lg-s4 ">
                    seed
                  </h4>
                </div>
              </div>
              <div className="col-4 col-mb-4 col-sm-4 col-md-4 ">
                <div className="cpn-head text-center ">
                  <h1 className="title title-lg-s1 text-color-green">
                    20%
                  </h1>
                  <h4 className=" text-uppercase  title-lg-s4 ">
                    team
                  </h4>
                </div>
              </div>
              <div className="col-4 col-mb-4 col-sm-4 col-md-4 ">
                <div className="cpn-head text-center ">
                  <h1 className="title title-lg-s1 text-color-green">
                    20%
                  </h1>
                  <h4 className=" text-uppercase  title-lg-s4 ">
                    Marketing Team
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className="row">




          <div className="col-12 no-padding">
            <br/>
            <br/>
            <br/>
            <br/>


            <Imgix width={2000} src="https://prod-martianarmy.imgix.net/v2/CHART.png" className={"utilities-img"} sizes={"90vw"}/>

          </div>

        </div>




      </div>
    </section>

  );
}