import React from 'react';
import APR2022 from "./roadmap/APR2022";
import MAY2022 from "./roadmap/MAY2022";
import JUN2022 from "./roadmap/JUN2022";
import JUL2022 from "./roadmap/JUL2022";
import AUG2022 from "./roadmap/AUG2022";
import SEP2022 from "./roadmap/SEP2022";
import OCT2022 from "./roadmap/OCT2022";
import NOV2022 from "./roadmap/NOV2022";
import DEC2022 from "./roadmap/DEC2022";
import Q22023 from "./roadmap/Q22023";
import JAN2023 from "./roadmap/JAN2023";
import Q32023 from "./roadmap/Q32023";
import Q42023 from "./roadmap/Q42023";
import MAR2023 from "./roadmap/MAR2023";
import FEB2023 from "./roadmap/FEB2023";
import Imgix from "react-imgix";


export default function ROADMAPV2() {
  return (
    <div id={"spacemap"}>

    {/*MOBILE VERSION*/}
    <section id="roadmap" className="section section-roadmap   display-desktop-none  tc-dark    bg-theme-martian-roadmap-background">
      <div id="roadmapContainer" className="container roadmapContainer bg-dark">
        <div className={"row  "}>
          <div className={"col-12"}>

            <div className="section-head section-head-sm text-center wide-auto-sm cpn-head ">
              <h1 className="title title-lg-s1 color-theme">OUR SPACEMAP</h1>
            </div>

            <div className={"row bg-dark img-50"}>
              <div className={"col-12"}>

                <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/15.png" sizes="100vw" className="float-left round-full"/>

              </div>
            </div>

            <APR2022/>

            <div className={"row bg-dark img-50"}>
              <div className={"col-12"}>
                <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/4.png" sizes="100vw" className="float-right round-full"/>
              </div>
            </div>


            <MAY2022/>

            <div className={"row bg-dark img-50"}>
              <div className={"col-12"}>
                <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/2.png" sizes="100vw" className="float-left round-full"/>
              </div>
            </div>

            <JUN2022/>

            <div className={"row bg-dark img-50"}>
              <div className={"col-12"}>
                <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/2.png" sizes="100vw" className="float-right round-full"/>
              </div>
            </div>

            <JUL2022/>

            <div className={"row bg-dark img-50"}>
              <div className={"col-12"}>
                <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/9.png" sizes="100vw" className="float-left round-full"/>
              </div>
            </div>

            <AUG2022/>

            <div className={"row bg-dark img-50"}>
              <div className={"col-12"}>
                <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/5.png" sizes="100vw" className="float-right round-full"/>
              </div>
            </div>

            <SEP2022/>

            <div className={"row bg-dark img-50"}>
              <div className={"col-12"}>
                <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/4.png" sizes="100vw" className="float-left round-full"/>
              </div>
            </div>

            <OCT2022/>

            <div className={"row bg-dark img-50"}>
              <div className={"col-12"}>
                <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/3.png" sizes="100vw" className="float-right round-full"/>
              </div>
            </div>

            <NOV2022/>

            <div className={"row bg-dark img-50"}>
              <div className={"col-12"}>
                <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/7.png" sizes="100vw" className="float-left round-full"/>
              </div>
            </div>

            <DEC2022/>

            <div className={"row bg-dark img-50"}>
              <div className={"col-12"}>
                <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/6.png" sizes="100vw" className="float-right round-full"/>
              </div>
            </div>

            <JAN2023/>

            <div className={"row bg-dark img-50"}>
              <div className={"col-12"}>
                <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/8.png" sizes="100vw" className="float-left round-full"/>
              </div>
            </div>

            <FEB2023/>

            <div className={"row bg-dark img-50"}>
              <div className={"col-12"}>
                <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/9.png" sizes="100vw" className="float-right round-full"/>
              </div>
            </div>

            <MAR2023/>

            <div className={"row bg-dark img-50"}>
              <div className={"col-12"}>
                <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/10.png" sizes="100vw" className="float-left round-full"/>
              </div>
            </div>

            <Q22023/>

            <div className={"row bg-dark img-50"}>
              <div className={"col-12"}>
                <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/12.png" sizes="100vw" className="float-right round-full"/>
              </div>
            </div>

            <Q32023/>

            <div className={"row bg-dark img-50"}>
              <div className={"col-12"}>
                <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/14.png" sizes="100vw" className="float-left round-full"/>
              </div>
            </div>

            <Q42023/>



          </div>
        </div>
      </div>
    </section>

  {/*DESKTOP VERSION*/}
    <section id="roadmap" className="section section-roadmap   display-mobile-none   bg-theme tc-dark    bg-theme-martian-roadmap-background">
      <div id="roadmapContainer" className="container roadmapContainer">
        <div className={"row  spacemap-border-right"}>
          {/*############## COLUMN 1 ##############*/}
          <div id={"column-1"} className={"col-mb-6 col-md-3"}>
            <div className={"row"}>
              <div className={"col-4 spacemap-border"}></div><div className={"col-4 spacemap-border"}></div><div className={"col-4 spacemap-border"}></div>
            </div>
            <div className={"row"}>
              <div className={"col-8 spacemap-border bg-dark"}><h2 className={"tc-theme ml-3"}>OUR SPACEMAP</h2></div><div className={"col-4 spacemap-border"}></div>
            </div>
            <div className={"row"}>
              <div className={"col-4 spacemap-border"}></div><div className={"col-4 spacemap-border "}></div><div className={"col-4 bg-dark spacemap-border "}>

              <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/11.png"  />
            </div>
            </div>
            <div className={"row"}>
              <div className={"col-4 spacemap-border bg-dark"}>
                <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/13.png"  />
              </div><div className={"col-4 spacemap-border bg-dark"}></div><div className={"col-4 spacemap-border bg-dark"}></div>
            </div>

            <JUL2022/>

            <div className={"row"}>
              <div className={"col-4 spacemap-border bg-dark"}>
                <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/1.png"  />
              </div><div className={"col-4 spacemap-border bg-dark"}></div><div className={"col-4 spacemap-border bg-dark"}></div>
            </div>
            <NOV2022/>

            <div className={"row"}>
              <div className={"col-4 spacemap-border bg-dark"}></div><div className={"col-4 spacemap-border bg-dark"}></div><div className={"col-4 spacemap-border bg-dark"}>
              <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/14.png"  />
            </div>
            </div>

            <MAR2023/>

            <div className={"row"}>
              <div className={"col-4 spacemap-border "}>
                <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/10.png"  />
              </div><div className={"col-4 spacemap-border bg-dark"}></div><div className={"col-4 spacemap-border bg-dark"}></div>
            </div>
            <div className={"row"}>
              <div className={"col-4 spacemap-border "}></div><div className={"col-4 spacemap-border"}></div><div className={"col-4 spacemap-border"}></div>
            </div>

          </div>



          {/*############## COLUMN 2 ##############*/}
          <div  id={"column-2"} className={"col-mb-6 col-md-3 "}>
            <div className={"row"}>
              <div className={"col-4 spacemap-border"}></div><div className={"col-4 spacemap-border"}></div><div className={"col-4 spacemap-border"}></div>
            </div>

            <APR2022/>

            <div className={"row bg-dark"}>
              <div className={"col-4 spacemap-border bg-dark"}></div><div className={"col-4 spacemap-border bg-dark"}></div><div className={"col-4 spacemap-border bg-dark"}>
              <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/7.png"  />
            </div>
            </div>

            <AUG2022/>

            <div className={"row bg-dark"}>
              <div className={"col-4 spacemap-border"}>
                <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/2.png"  />
              </div><div className={"col-4 spacemap-border bg-dark"}></div><div className={"col-4 spacemap-border bg-dark"}></div>
            </div>

            <DEC2022/>

            <div className={"row bg-dark"}>
              <div className={"col-4 spacemap-border bg-dark"}></div><div className={"col-4 spacemap-border bg-dark"}></div><div className={"col-4 spacemap-border"}>
              <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/3.png"  />
            </div>
            </div>

            <Q22023/>

            <div className={"row"}>
              <div className={"col-4 spacemap-border"}></div><div className={"col-4 spacemap-border"}></div><div className={"col-4 spacemap-border"}></div>
            </div>
          </div>


          {/*############## COLUMN 3 ##############*/}
          <div  id={"column-3"} className={"col-mb-12 col-md-3"}>
            <div className={"row"}>
              <div className={"col-4 spacemap-border"}></div><div className={"col-4 spacemap-border"}></div><div className={"col-4 spacemap-border"}></div>
            </div>

            <div className={"row bg-dark"}>
              <div className={"col-4 spacemap-border bg-dark"}></div><div className={"col-4 spacemap-border bg-dark"}></div><div className={"col-4 spacemap-border"}>
              <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/4.png"  />
            </div>
            </div>

            <MAY2022/>

            <div className={"row bg-dark"}>
              <div className={"col-4 spacemap-border"}>
                <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/6.png"  />
              </div><div className={"col-4 spacemap-border bg-dark"}></div><div className={"col-4 spacemap-border bg-dark"}></div>
            </div>

            <SEP2022/>


            <div className={"row bg-dark"}>
              <div className={"col-4 spacemap-border bg-dark"}></div><div className={"col-4 spacemap-border bg-dark"}></div><div className={"col-4 spacemap-border"}>
              <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/5.png"  />
            </div>
            </div>

            <JAN2023/>

            <Q32023/>

            <div className={"row"}>
              <div className={"col-4 spacemap-border"}></div><div className={"col-4 spacemap-border"}></div><div className={"col-4 spacemap-border"}></div>
            </div>

          </div>

          {/*############## COLUMN 4 ##############*/}
          <div  id={"column-4"} className={"col-mb-12 col-md-3"}>
            <div className={"row"}>
              <div className={"col-4 spacemap-border"}></div><div className={"col-4 spacemap-border"}></div><div className={"col-4 spacemap-border"}></div>
            </div>

            <JUN2022/>

            <div className={"row bg-dark"}>
              <div className={"col-4 spacemap-border bg-dark"}></div><div className={"col-4 spacemap-border bg-dark"}></div><div className={"col-4 spacemap-border"}>
              <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/8.png"  />
            </div>
            </div>

            <OCT2022/>

            <div className={"row bg-dark"}>
              <div className={"col-4 spacemap-border bg-dark"}></div><div className={"col-4 spacemap-border bg-dark"}></div><div className={"col-4 spacemap-border"}>
              <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/12.png"  />
            </div>
            </div>

            <FEB2023/>

            <div className={"row bg-dark"}>
              <div className={"col-4 spacemap-border bg-dark"}></div><div className={"col-4 spacemap-border bg-dark"}></div><div className={"col-4 spacemap-border"}>
              <Imgix width={120} src="https://prod-martianarmy.imgix.net/martian_army/10.png" />
            </div>
            </div>

            <Q42023/>

            <div className={"row"}>
              <div className={"col-4 spacemap-border"}></div><div className={"col-4 spacemap-border"}></div><div className={"col-4 spacemap-border"}></div>
            </div>

          </div>

        </div>

      </div>
    </section>

    </div>
  );
}