import { Button } from '@material-ui/core';
import React from 'react';
import Imgix from "react-imgix";

export default function FAQ() {
  return (
    <div id={"FAQ-SECTION"} className="container section">
      <div className={"row"}>

        <div className="flex-fill col-mb-12 col-sm-6">

          {/*MOBILE*/}
          <div className={"display-desktop-none"}>
            <div className="section-head section-head-sm cpn-head ">
              <h1 className="title title-lg-s1 color-theme  text-center">FAQ</h1>
            </div>
          </div>

          <h4 className="lead text-uppercase color-theme">What is Staking?</h4>
          <p>

            Staking means that you "lock" your NFT in a "smart contract" on Solana blockchain.
            It still remains under your control and you can "unlock" your NFT at any time.
            In exchange for locking your NFT, you get to earn the project's native token, $MARS, which can be used in the Martian ecosystem.
          </p>

          <h4 className="lead text-uppercase color-theme">
            Want to stake your martian army?
          </h4>

            <Button
              id="faq-spaceship"
              className="theme discord bold-text btn-round btn-md"
              href="https://main.d27xb4rgthcnfo.amplifyapp.com/"
              rel="noreferrer"
            >
              GENESIS SPACESHIP
            </Button>


          <h4 className="lead text-uppercase color-theme">
            Want to trade $MARS?
          </h4>
          <p>Dexlab coming soon</p>

          <h4 className="lead text-uppercase color-theme">
            What is the $MARS token address?
          </h4>

            <Button
              id="faq-mars"
              className="theme discord bold-text btn-round btn-md"
              href="https://solscan.io/token/67jSkrp4SEhk4k5E6oXjprX9SXnRb1x2M3obke7BemL1"
              rel="noreferrer"
            >
              SOLSCAN ADDRESS
            </Button>


          <h4 className="lead text-uppercase color-theme">
            What is the maximum total supply of $MARS?
          </h4>
          <p>1,000,000,000</p>

          <h4 className="lead text-uppercase color-theme">
            How much $MARS does a staked martian earn per day?
          </h4>
          <p>150 $MARS per day</p>

          <h4 className="lead text-uppercase color-theme">
            How long do you have to stake your MARTIAN to earn $MARS?
          </h4>
          <p>
            You can stake for however long you would like, and un-stake whenever you choose.
            You will receive the allotment of $MARS based on the amount of time your Martian was staked
          </p>
        </div>

        <div className="flex-filld-table-row align-content-end  col-mb-12 col-sm-5 offset-sm-1 cpn-head display-mobile-none">
          <div className={""}>
            <h1 id={"FAQ-TITLE-FOOTER"} className="h1 title title-lg-s2">
              <span className="color-theme d-block">FAQ</span>
              <span className="text-light d-block">FREQUENTLY</span>
              <span className="text-light d-block">ASKED</span>
              <span className="text-light d-block">QUESTIONS</span>
            </h1>
          </div>
          <div className={"fix-bottom"}>
            <Imgix width={700} src="https://prod-martianarmy.imgix.net/v2/35.png" sizes="50vw" className="align-self-end"/>

          </div>
        </div>

      </div>

    </div>
  );
}
