import React from 'react';


export default function OCT2022() {
  return (
    <div className={"bg-theme rounded-lg"}>
      <div className={"row align-middle"}>
        <div className={"col-5 spacemap-border d-flex justify-content-center"}><h2 className={"ml-3 align-self-center bold-text"}>Oct 2022</h2></div>
        <div className={"col-7 d-flex justify-content-center  spacemap-border no-border-left"}><h5 className={"align-self-center"}>DAO <br/>& Gov Token</h5></div>
      </div>
      <div className={"row"}>
        <div className={"col-12 spacemap-border-2x"}>
          <p className={"ml-3 tc-dark bold-text"}>
            ›Governance Token for Voting rights<br/>
            ›Martian Army DAO creation<br/>
          </p>
        </div>
      </div>

    </div>
    

  );
}