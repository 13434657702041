import React from 'react';


export default function Q42023() {
  return (
    <div className={"bg-theme rounded-lg"}>
      <div className={"row"}>
        <div className={"col-5 spacemap-border d-flex justify-content-center"}><h2 className={"ml-3 align-self-center bold-text"}>Q4 2023</h2></div>
        <div className={"col-7 d-flex justify-content-center  spacemap-border no-border-left"}><h5 className={"align-self-center"}>Wave 4 Release<br/></h5></div>
      </div>
      <div className={"row"}>
        <div className={"col-12 spacemap-border-2x"}>
          <p className={"ml-3 tc-dark bold-text"}>
            ›Wave 4 Release of Martian Army new Collection (New Artist voted by the DAO)<br/>
            ›Martian Army sponsored SpaceX Mars mission (voted by DAO)<br/>
          </p>
        </div>
      </div>

    </div>
    

  );
}