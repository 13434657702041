import { Button } from '@material-ui/core';
import React from 'react';

export default function HEADERBANNER() {
  return (

    <div className="header-banner bg-theme-martian-background has-ovm has-mask" id="home">
      <div className="nk-banner banner-pic">
        <div className="banner banner-mask-fix banner-fs banner-single tc-light">
          <div className="banner-wrap ov-v">
            <div className="container">
              <div className="row align-items-center justify-content-center justify-content-lg-between">

                <div className="col-lg-7 col-sm-9">
                  <div className="banner-caption wide-auto text-center text-lg-left">
                    <div className="cpn-head mt-0 tc-theme">
                      <h1 className="title title-lg-s1 no-line-height text-uppercase text-color-green">
                        Say <span  className="text-color-white">Hello</span><br/>
                        to our new<br/>
                        Martian<br/>
                        Overlords</h1>
                    </div>

                    <div className="cpn-text cpn-text-s2">
                      <p className="lead text-left minting-text">
                      </p>
                    </div>
                    <div className="discord-box ">
                      <Button id="discord"
                              className="bold-text btn-round btn-md spaceship-active"
                              href="https://magiceden.io/marketplace/marsarmy" rel="noreferrer">
                        Magic Eden&nbsp;&nbsp;
                        <img src={"/images/partner/magic-eden.png"} width={"32px"} alt={"Magic Eden"}/>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}