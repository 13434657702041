import TOPMENU from "./components/TOP-MENU";
import FOOTERCONTENT from "./components/FOOTERCONTENT";


export interface SpaceshipProps {

}

const Spaceship = (props: SpaceshipProps) => {

  return (
    <div>
      <header className="nk-header page-header is-transparent is-sticky is-shrink is-dark has-fixed" id="header">
        <TOPMENU/>
        <div className=" bg-dark has-ovm  zero-margin-bottom" id="home">

            <div className=" ov-v zero-margin-bottom margin-top-99">
              <iframe title={"spaceship"} className={"spaceship-frame"} src="https://main.d27xb4rgthcnfo.amplifyapp.com/"/>
            </div>

        </div>
      </header>
      <footer className="nk-footer bg-theme tc-dark bg-theme-martian-footer-background">
        <FOOTERCONTENT/>
      </footer>

    </div>
  );
};

export default Spaceship;
