import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';

import 'roboto-regular';
import './css/index.css';
import './css/style-azalea.css';
import 'font-awesome/css/font-awesome.min.css';
import {
  ENV,
  GA_AUTH_DEV,
  GA_AUTH_LIVE,
  GA_PREVIEW_DEV,
  GA_PREVIEW_LIVE,
  GTM_CONTAINER_ID,
} from './constants';

const tagManagerArgs = {
  gtmId: GTM_CONTAINER_ID,
  //the below changes GTM values based on whether dev or production
  auth:
    ENV === 'development'
      ? GA_AUTH_DEV // dev ga_auth
      : GA_AUTH_LIVE, // live ga_auth
  preview:
    ENV === 'development'
      ? GA_PREVIEW_DEV // dev ga_env
      : GA_PREVIEW_LIVE, // live ga_env
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
