import React from 'react';


export default function JUL2022() {
  return (
    <div className={"bg-theme rounded-lg"}>
  <div className={"row"}>
    <div className={"col-5 spacemap-border d-flex justify-content-center"}><h2 className={"ml-3 align-self-center bold-text"}>Jul 2022</h2></div>
    <div className={"col-7 d-flex justify-content-center  spacemap-border no-border-left"}><h5 className={"align-self-center"}>Expansion Pack</h5></div>
  </div>
  <div className={"row"}>
    <div className={"col-12 spacemap-border-2x"}>
      <p className={"ml-3 tc-dark bold-text"}>
        ›222 Limited Edition Expansion [Special Forces] with extra strength on attack for gaming<br/>
        ›Early access to future collections<br/>
      </p>
    </div>
  </div>
</div>
  );
}