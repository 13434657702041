import React from 'react';
import Mailchimp from "react-mailchimp-form";
import Imgix from "react-imgix";

export default function FOOTERCONTENT() {
  return (


    <div id="footer" className="pb-0">

      <div className="container py-4">

        <div className="row">


          <div className="col-md-6 ">
            <div className={"row "}>
              <div className={"col-12 zero-padding text-left"}>
                <h4 className="title title-lg-s2">
                  Stay up to date
                </h4>
              </div>

              <div className="col-12 col-sm-12 col-md-10 newsletter-box tc-light mb-4">
                <Mailchimp
                  action='https://space.us20.list-manage.com/subscribe/post?u=cd19335138a5b40c03183b539&amp;id=6531345f60'
                  fields={[
                    {
                      name: 'EMAIL',
                      placeholder: 'Your email',
                      type: 'email',
                      required: true,

                      class: 'placeholder-color'
                    }
                  ]}
                />
              </div>
            </div>

            <div className="row text-left mb-4">
              <h4 className="title title-lg-s2">Join our communities.</h4>
              <ul className="social ">
                <li><a target="_blank" href="https://twitter.com/_martianarmy_" rel="noreferrer"> <em className="social-icon fab fa-3x fa-twitter"></em> </a></li>
                <li><a target="_blank" href=" https://discord.gg/martianarmy" rel="noreferrer"> <em className="social-icon fab fa-3x fa-discord social-icon-left"></em> </a></li>
                <li><a target="_blank" href="https://www.instagram.com/martianarmy.space/" rel="noreferrer"> <em className="social-icon fab fa-3x fa-instagram social-icon-left"></em> </a></li>
                <li><a target="_blank" href="https://medium.com/@martianarmy" rel="noreferrer"> <em className="social-icon fab fa-3x fa-medium social-icon-left"></em> </a></li>
                <li><a target="_blank" href="https://www.youtube.com/channel/UCqTtT-nIpb7KwG9CvqF23mQ/featured" rel="noreferrer"> <em className="social-icon fab fa-3x fa-youtube social-icon-left"></em> </a></li>
              </ul>
            </div>

          </div>

          <div className="col-md-6">
            <div className={"row mb-4"}>
              <div className={"col-12 zero-padding text-left"}>
                  <h4 className="title title-lg-s2">As Seen on</h4>
              </div>

              <div className="row text-left  mb-4">
                <ul className="social ">



                  <li>
                    <a href="https://riseangle.com/nft-calendar/event/martian-army"  rel="noreferrer" target="_blank" className={"float-left left-margin-10 margin-bottomminus-10"}>
                      <Imgix src="https://riseangle.com/assets/img/header-logo.png"  width={90} />
                    </a>
                  </li>
                  <li>
                    <a href="https://notfin.com/martian-army/" target="_blank"  rel="noreferrer" className={"float-left left-margin-20"}>
                      <Imgix src="https://notfin.com/logo/white-notfin.png"  width={130} />
                    </a>
                  </li>

                  <li>
                    <a href="https://nftcalendar.io/event/martian-army-nft/" target="_blank" rel="noreferrer" className={"float-left left-margin-20 margin-bottomminus-10"}>
                      <Imgix src="https://prod-martianarmy.imgix.net/v2/marketplaces/nftcalendar-icon.svg" sizes={"25vw"} width={60} imgixParams={{q: "80"}}/>
                    </a>
                  </li>
                  <li>
                    <a href="https://magiceden.io/marketplace/marsarmy" target="_blank"  rel="noreferrer" className={"float-left left-margin-20 margin-bottomminus-10"}>
                      <Imgix src="https://prod-martianarmy.imgix.net/images/partner/magic-eden.png"  width={80} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row text-left display-mobile-none">
              <h4 className="title title-lg-s2">Partners</h4>
              <ul className="social partners-ul align-items-center">


                <li>
                  <Imgix src="https://upload.wikimedia.org/wikipedia/commons/5/51/Font_Awesome_5_brands_aws.svg" className={"float-left align-top"} sizes={"30vw"} width={80} imgixParams={{q: "80"}}/>
                </li>
                <li className={""}>
                  <a href="https://hi.qwestive.io/" target="_blank" rel="noreferrer" className={"float-left left-margin-20 margin-top-10 "}>
                    <Imgix src="https://prod-martianarmy.imgix.net/v2/partner/qwestive.svg" sizes={"25vw"} width={150} imgixParams={{q: "80"}}/>
                  </a>
                </li>
                <li className={"display-mobile-none"}>
                  <a href="https://solana.io/" target="_blank" rel="noreferrer" className={"float-left left-margin-20"}>
                    <Imgix src="https://prod-martianarmy.imgix.net/v2/partner/svg0.png" sizes={"15vw"} width={50} imgixParams={{q: "80"}}/>
                    <Imgix src="https://prod-martianarmy.imgix.net/v2/partner/Company [STYLESTYLE3][TAGH4].png" sizes={"15vw"} width={210} imgixParams={{q: "80"}}/>
                  </a>
                </li>
              </ul>
            </div>


          </div>


        </div>

      </div>
      <div className="col-md-12 bg-dark tc-light">
        <div className="copyright-text copyright-text-s2 pdt-m text-center mb-3">
          <p><span className="d-sm-block">Copyright © 2022 Martian Army</span></p>
        </div>
      </div>
    </div>


  );
}