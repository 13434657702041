import React from 'react';


export default function SEP2022() {
  return (

    <div className={"bg-theme rounded-lg"}>
      <div className={"row align-middle"}>
        <div className={"col-5 spacemap-border d-flex justify-content-center"}><h2 className={"ml-3 align-self-center bold-text"}>Sep 2022</h2></div>
        <div className={"col-7 d-flex justify-content-center  spacemap-border no-border-left"}><h5 className={"align-self-center"}>Evolution 3.0<br/>& Open Call</h5></div>
      </div>
      <div className={"row"}>
        <div className={"col-12 spacemap-border-2x"}>
          <p className={"ml-3 tc-dark bold-text"}>
            ›You can evolve you baby Martian into a full adult using $MARS<br/>
            ›Open Call for New Artist for next Edition, Incubator, and Evolution traits<br/>

          </p>
        </div>
      </div>
    </div>

  );
}