import TOPMENU from "./components/TOP-MENU";


export interface WhitePaperProps {
}

const WhitePaper = (props: WhitePaperProps) => {

  return (
    <div>
      <header className="nk-header page-header is-transparent is-sticky is-shrink is-dark has-fixed" id="header">
        <TOPMENU/>
        <div className="header-banner bg-dark has-ovm has-mask"id="home">
          <div className="nk-banner">
            <div className="banner-mask-fix banner-fs banner-single tc-light">
              <div className="banner-wrap ov-v">
                <div className="container">
                  <div className="row align-items-center justify-content-center justify-content-lg-between">
                    <div className="col-lg-12" >
                      <iframe title={"whitepaper"} className={"whitepaper-frame"} src="https://martian-army.gitbook.io/martian-army/"/>
                    </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default WhitePaper;
