import React from 'react';

export default function MARKEE() {
  return (

    <div className="marquee zero-padding zero-margin bg-color-green">
      <div className="zero-padding zero-margin track">
        <div className="stage-info content">
          <span className="title title-xs-s2 to-uppercase tc-light">Price per Mint</span>
          <span className="text-color-black">&nbsp;1.5 SOL</span>&nbsp;&nbsp;&nbsp;&nbsp;
          <span className="title title-xs-s2 to-uppercase tc-light">Price per Mint</span>
          <span className="text-color-black">&nbsp;1.5 SOL</span>&nbsp;&nbsp;&nbsp;&nbsp;
          <span className="title title-xs-s2 to-uppercase tc-light">Price per Mint</span>
          <span className="text-color-black">&nbsp;1.5 SOL</span>&nbsp;&nbsp;&nbsp;&nbsp;
          <span className="title title-xs-s2 to-uppercase tc-light">Price per Mint</span>
          <span className="text-color-black">&nbsp;1.5 SOL</span>&nbsp;&nbsp;&nbsp;&nbsp;
          <span className="title title-xs-s2 to-uppercase tc-light">Price per Mint</span>
          <span className="text-color-black">&nbsp;1.5 SOL</span>&nbsp;&nbsp;&nbsp;&nbsp;
          <span className="title title-xs-s2 to-uppercase tc-light">Price per Mint</span>
          <span className="text-color-black">&nbsp;1.5 SOL</span>&nbsp;&nbsp;&nbsp;&nbsp;
          <span className="title title-xs-s2 to-uppercase tc-light">Price per Mint</span>
          <span className="text-color-black">&nbsp;1.5 SOL</span>&nbsp;&nbsp;&nbsp;&nbsp;
          <span className="title title-xs-s2 to-uppercase tc-light">Price per Mint</span>
          <span className="text-color-black">&nbsp;1.5 SOL</span>&nbsp;&nbsp;&nbsp;&nbsp;
          <span className="title title-xs-s2 to-uppercase tc-light">Price per Mint</span>
          <span className="text-color-black">&nbsp;1.5 SOL</span>&nbsp;&nbsp;&nbsp;&nbsp;
          <span className="title title-xs-s2 to-uppercase tc-light">Price per Mint</span>
          <span className="text-color-black">&nbsp;1.5 SOL</span>&nbsp;&nbsp;&nbsp;&nbsp;
          <span className="title title-xs-s2 to-uppercase tc-light">Price per Mint</span>
          <span className="text-color-black">&nbsp;1.5 SOL</span>&nbsp;&nbsp;&nbsp;&nbsp;
          <span className="title title-xs-s2 to-uppercase tc-light">Price per Mint</span>
          <span className="text-color-black">&nbsp;1.5 SOL</span>&nbsp;&nbsp;&nbsp;&nbsp;
          <span className="title title-xs-s2 to-uppercase tc-light">Price per Mint</span>
          <span className="text-color-black">&nbsp;1.5 SOL</span>&nbsp;&nbsp;&nbsp;&nbsp;
          <span className="title title-xs-s2 to-uppercase tc-light">Price per Mint</span>
          <span className="text-color-black">&nbsp;1.5 SOL</span>&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      </div>
    </div>

  );
}