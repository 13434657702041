export interface NFTGameProps {}

const NFTGame = (props: NFTGameProps) => {


  return (
    <div>
      <main className="nk-pages">

                <div className="container">
                  <div className="row align-items-center justify-content-center justify-content-lg-between">
                    <div className="col-lg-12" >
                        <h1 className={"text-color-white"}> Martian Army</h1>
                    </div>
                </div>


        </div>
      </main>
    </div>
  );
};

export default NFTGame;
